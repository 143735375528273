import React from 'react';
import './style/bigTitle.scss'

function BigTitle(props) {
    return (
        <div className="bigTitle">
            <h2 className="bigTitle__text">{props.title}</h2>
        </div>
    );
}

export default BigTitle;