import React from 'react';
import "./style/arrowLink.scss"
import { AiOutlineDown } from "react-icons/ai";
import { AnchorLink } from "gatsby-plugin-anchor-links";


function ArrowLink(props) {
    return (
        <div className="arrow">
            <AnchorLink to="/#kontakt" className="arrow__link">
                <AiOutlineDown/>
            </AnchorLink>
        </div>
    );
}

export default ArrowLink;