import React from 'react';
import "./style/smallTitle.scss"

function SmallTitle(props) {
    return (
        <div className="smallTitle">
            <h3 className="smallTitle__text">
                {props.title}
            </h3>
        </div>
    );
}

export default SmallTitle;