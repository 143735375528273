import React from 'react';
import {useStaticQuery, graphql} from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "./style/backgroundHero.scss"
import ArrowLink from "./ArrowLink";


export default BackgroundHero;

function BackgroundHero(props) {
    const data = useStaticQuery(graphql`
    query {
      mainHero: file(relativePath: { eq: "mainHero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      eCommerce: file(relativePath: { eq: "eCommerce.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      website: file(relativePath: { eq: "website.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      testing: file(relativePath: { eq: "testing.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      graphic: file(relativePath: { eq: "graphic.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      seo: file(relativePath: { eq: "seo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

    const img = props.img
    return (
        <section className="heroImage">
            <BackgroundImage
                className="heroImage__image"
                fluid={data[img].childImageSharp.fluid}>
                {props.isArrowLink ? <div className="heroImage__content">
                    <p>Podziel się swoim pomysłem</p>
                    <ArrowLink/>
                </div> : ""}
            </BackgroundImage>
        </section>
    );
}
